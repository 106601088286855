@use "../../styles/animation.scss";

$banner-animation-duration: 2s;
$button-animation-duration: 0.5s;
$loading-animation-duration: 2.5s;

.query-box {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1 0;
  // height: 3.5em;
  padding: 10px 10px;
  position: relative;
  border-top: 1px solid #dcdce4;

  .max-terms-warning {
    flex-grow: 1;
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
  }

  .conversation-terms {
    font-size: 12px;
    margin-left: 10px;
    // font-weight: bold;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 5px;

  }

  input {
    flex-grow: 1;
    border-width: 0px;
    font-size: 14px;
    font-family: "Source Sans Pro", "Segoe UI", sans-serif;
    height: 100%;
  }

  button {
    border-width: 0px;
    background: #fff;
    cursor: pointer;
  }

  button:disabled {
    opacity: 40%;
    cursor: default;
  }

  img {
    height: 29px;
    width: 29px;
  }

  .loading-icon {
    -webkit-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
    -moz-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
    -ms-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
    -o-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
    animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
  }

  @keyframes rotating-no-delay {
    100% {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}