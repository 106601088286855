.chat-bubble {
    position: relative;
    padding: 0px 16px;
    min-width: 1em;
    max-width: 80%;
    white-space: pre-line;
    white-space: normal;

    .chat-bubble-container{
        margin: 16px 0px;
    }

    & table {

        position: relative;
        display: block;
        max-height: 60vh;
        overflow-x: auto;

        border-collapse: collapse;

        th,
        td {
            padding: .95em;
        }

        thead {
            position: sticky;
            top: 0;
            background-color: #FFF;

            th {
                background-color: var(--company-identity-color);
                border-right: #FFF 1px solid;
                color: #FFF;
            }
        }

        td:nth-child(odd) {
            background-color: rgb(240, 233, 233);
        }

        td {
            border-bottom: 2px solid var(--company-identity-color);
        }

    }
}

.chat-bubble--tool-call {
    min-width: 1em;
    max-width: 60%;
    height: fit-content;
    overflow: hidden;

    .bubble__content {
        padding: 1em 16px;
    }

    .tool-call__footer {
        display: flex;
        color: #FFFFFF;
        background-color: var(--company-identity-color);
        width: 100%;
        padding: .5em 16px;
        align-items: center;
        column-gap: .5em;
        font-size: .8rem;
        font-weight: bold;
    }

    .tool-call__icon {
        width: 1.2rem;
    }

    .tool-call__icon--rotating {
        width: 1.2rem;
        animation: rotating-bot-icon linear 2000ms infinite;
    }
}

.chat-bubble-user {
    border-radius: 20px 0px 20px 20px;
}

.chat-bubble-assistant {
    border-radius: 0px 20px 20px 20px;
}

.chat-bubble-content {
    border-width: 1px;
    border-radius: 8px;
    padding: 0.25em 1em;
    margin: 0.5em;
}

.chat-bubble-error {
    font-style: italic;
    color: grey;
}

.context-tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div {
        background-color: var(--company-identity-color);
        border-radius: 5px;
        color: white;
        font-weight: 500;
        width: fit-content;
        padding: 0.5em;
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
}

.supplement-info {
    border-top: 1px solid #afafaf;
    margin-top: 0.7em;

    .detail-message {
        padding: 0.25em 0em 0.25em 0em;
        margin: 0.25em 0em 0.25em 0em;
    }

    .source-detail-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .detail-box {
            border: 1px #cccccc solid;
            padding: 0.5em;
            margin: 0.25em;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}

.aia-bot-logo-container {
    position: relative;
    overflow: hidden;
    width: 35px;
    height: 35px;
    margin-right: 6px;
    border-radius: 50%;

    .bot-logo {
        position: absolute;
    }

    .bot-aia-logo {
        position: absolute;
        width: auto;
        height: 100%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.bot-logo {
    width: 35px;
    height: 35px;
    margin-right: 6px;
    border-radius: 50%;
}

.message-icons {
    height: 30px;
    display: flex;
    justify-content: flex-end;

    button {
        width: 30px;
        height: 30px;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 5px;
        float: right;
        opacity: 0.6;

        img {
            width: 20px;
            height: 20px;
        }

        .fact-check-logo {
            width: 22px;
            height: 22px;
        }
    }

    button:disabled {
        opacity: 0.3;
    }

    .left-radius {
        border-radius: 5px 0px 0px 5px
    }

    .right-radius {
        border-radius: 0px 5px 5px 0px
    }
}

dialog.paragraph-source {
    text-align: center;
    border: none;
    border-radius: 10px;
    width: 60vw;
    height: 70vh;
    max-width: 850px;
    filter: drop-shadow(0rem 0rem 1.5rem #7e7e7e);
    overflow: hidden;

    .option-box-header {
        font-size: clamp(13px, 2vw, 20px);
    }

    button.xmark-button {
        width: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
        position: absolute;
        right: 20px;
    }

    .source-text {
        text-align: left;
        flex-grow: 1;
        overflow: auto;
        height: 90%;
        width: 100%;
        margin-bottom: 1vw;
        border-bottom: 1.5px solid #ccc;

        div.source-paragraph {
            padding: max(1vw, 10px);
            ;
            margin: max(1.5vw, 15px);
            display: block;
            text-align: left;
            background-color: #FFFFFF;
            border-radius: 6px;
            box-shadow: 0px 2px 4px 4px #dcdcdc;
            font-size: clamp(13px, 1.2vw, 17px);

            .highlight {
                font-weight: 600;
                background-color: #fffdbe;
            }
        }
    }

    .source-reference {
        height: auto;
        font-size: clamp(13px, 1.7vw, 17px);
    }
}

dialog.feedback-content {
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 30px;
    width: 75vw;
    max-width: 400px;
    height: 50vh;
    overflow: hidden;
    /* min-height: 250px; */
    filter: drop-shadow(0rem 0rem 1.5rem #7e7e7e);

    button {
        display: block;
        font-size: 1em;
        margin: 0.25em 0.25em;
        border-radius: 5px;
        outline: none;
        width: 40%;
        padding: 5px;
    }

    button.cancel {
        background-color: transparent;
        color: #939393;
        border: 1px solid #939393;
    }

    button.send {
        background-color: #006eff;
        color: white;
        font-weight: bold;
        border: none;
    }

    textarea {
        width: 100%;
        max-height: 100%;
        padding: 10px;
        resize: none;
        font-size: 14px;
        border: 1px solid #7e7e7e;
        border-radius: 5px;
    }

    .feedback-container {
        display: flex;
        height: 90%;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 0.5em 0.5em;
    }
}

.feedback-content-input {
    height: 90%
}

@-webkit-keyframes rotating-bot-icon {
    0% {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50%,
    100% {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating-bot-icon {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating-bot-icon {
    -webkit-animation: rotating-bot-icon 4s 3s ease-in-out infinite;
    -moz-animation: rotating-bot-icon 4s 3s ease-in-out infinite;
    -ms-animation: rotating-bot-icon 4s 3s ease-in-out infinite;
    -o-animation: rotating-bot-icon 4s 3s ease-in-out infinite;
    animation: rotating-bot-icon 4s 3s ease-in-out infinite;
}